import { Col, Row, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { getTagsPaginated, deleteTagById } from '../../../services/tags';
import AdminTableData from '../components/adminTableData';
import AdminPagination from '../components/pagination';
import { ToastContainer, toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { HeadFC, Link, navigate } from 'gatsby';
import SideNav from '../components/sidenav';
import AuthContext from '../contexts/auth';

import './index.scss';

const AdminNewsTags = () => {
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [newsIdToDelete, setNewsIdToDelete] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    searchText: '',
    totalPages: 0,
    totalElements: 0,
    data: []
  });

  const fetchNewsCategoriesPaginated = async () => {
    setLoading(true);
    setPagination((prevState) => ({ ...prevState, data: [] }));
    const paginatedResult = await getTagsPaginated(pagination.page, pagination.pageSize, pagination.searchText || '');
    setPagination(paginatedResult.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchNewsCategoriesPaginated()
      .catch(console.error);
  }, [pagination.page, pagination.pageSize]);

  const onEditNewsCategory = (newsCategoryId: number) => {
    navigate(`/admin/tags-de-noticias/${newsCategoryId}`);
  }

  const onDeleteNews = async () => {
    await toast.promise(sendDeleteNewsCategory,
      {
        pending: 'Eliminando el tag de noticia',
        success: 'Tag de noticia eliminada 👌',
        error: 'Error al eliminar tag de noticia 🤯'
      });

    setIsDeleteModalOpened(false);
    fetchNewsCategoriesPaginated()
      .catch(console.error);
  }

  const sendDeleteNewsCategory = async () => {
    await deleteTagById(newsIdToDelete);
  }

  const openDeleteNewsModal = (userId: string) => {
    setNewsIdToDelete(userId);
    toggleDeleteModal();
  }

  const toggleDeleteModal = () => {
    setIsDeleteModalOpened((prevState) => !prevState);
  }

  return (
    <AuthContext>
      <ToastContainer />
      <Modal isOpen={isDeleteModalOpened} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Borrar tag de noticia</ModalHeader>
        <ModalBody>
          ¿Está seguro de que desea eliminar el ID de tag de noticia?<br />
          <b>ID: {newsIdToDelete}</b>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => onDeleteNews()}>
            Eliminar
          </Button>{' '}
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <div className="admin-container d-flex flex-row w-100">
        <SideNav
          activeMenu='tags-de-noticias'
        />
        <Row className="admin-page-content d-flex flex-column align-items-center">
          <Col md={11}>
            <Card className="w-100 p-4 mb-5">
              <CardBody>
                <h4>Tags de noticias</h4>
                <Link
                  to="/admin/tags-de-noticias/nuevo"
                  className="btn btn-success mt-2"
                >
                  Crear tag de noticias
                </Link>
                <div className="overflow-x-auto">
                  <AdminTableData
                    loading={loading}
                    data={pagination.data}
                    columnNames={['# ID', 'Nombre', 'Última actualización']}
                    columnValues={['id', 'name', 'updatedAt']}
                    onEdit={onEditNewsCategory}
                    onDelete={openDeleteNewsModal}
                  />
                  <AdminPagination
                    page={pagination.page}
                    pageSize={pagination.pageSize}
                    totalPages={pagination.totalPages}
                    setPagination={setPagination}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </AuthContext>
  );
}

export default AdminNewsTags;

export const Head: HeadFC = () => <title>LaBotana : Admin - Tags de Noticias</title>
