import { api } from './api';

export const getTagsPaginated = (page: number, pageSize: number, searchText: string) => {
  return api.get(`/v1/tags/paginated?page=${page}&pageSize=${pageSize}&searchText=${searchText}`);
}

export const getAllTags = () => {
  return api.get(`/v1/tags/all`);
}

export const getTagById = (tagId: string) => {
  return api.get(`/v1/tags/${tagId}`);
}

export const createTagById = (id: string, name: string) => {
  return api.post(`/v1/tags`, { id, name });
}

export const updateTagById = (id: string, name: string) => {
  return api.put(`/v1/tags`, { id, name });
}

export const deleteTagById = (newsId: string) => {
  return api.delete(`/v1/tags/${newsId}`);
}
